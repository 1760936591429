import { Menu } from '@app/models/menu.model';

export const menuConfig: Menu[] = [
    {
        headerTitle: 'MENU.CONTENT',
        roles: [],
    },
    {
        title: 'MENU.ATTRACTIONS',
        path: '/dashboard/content/attractions',
        icon: 'icofont icofont-star',
        routeName: 'attractions',
        name: 'attractions',
        active: false,
        type: 'link',
        roles: [],
    },
    {
        title: 'MENU.CATEGORIES',
        path: '/dashboard/content/categories',
        icon: 'icofont icofont-tag',
        routeName: 'categories',
        roles: [],
        name: 'categories',
        type: 'link',
        active: false,
    },
    {
        title: 'MENU.HOTELS',
        path: '/dashboard/content/hotels',
        icon: 'icofont icofont-hotel',
        routeName: 'hotels',
        name: 'hotels',
        active: false,
        type: 'link',
        roles: [],
    },
    {
        title: 'MENU.KIOSK_NAMES',
        active: false,
        path: '/dashboard/content/kioskNames',
        icon: 'icofont icofont-imac',
        routeName: 'kioskNames',
        name: 'kioskNames',
        type: 'link',
        roles: [],
    },
    {
        title: 'MENU.PAGES',
        icon: 'icofont icofont-file-document',
        path: '/dashboard/content/pages',
        routeName: 'pages',
        active: false,
        name: 'pages',
        type: 'link',
        roles: [],
    },
    {
        title: 'MENU.POIS',
        icon: 'icofont icofont-bullseye',
        path: '/dashboard/content/pois',
        routeName: 'pois',
        name: 'pois',
        active: false,
        type: 'link',
        roles: [],
    },
    {
        title: 'MENU.VIDEOS',
        path: '/dashboard/content/videos',
        icon: 'icofont icofont-video',
        routeName: 'videos',
        name: 'videos',
        active: false,
        type: 'link',
        roles: [],
    },
    {
        headerTitle: 'MENU.FEATURES',
        roles: [],
    },
    {
        title: 'MENU.EMERGENCY',
        path: '/dashboard/content/emergency',
        icon: 'icofont icofont-warning',
        routeName: 'emergency',
        name: 'emergency',
        active: false,
        type: 'link',
        roles: [],
    },
    {
        headerTitle: 'MENU.CONFIG',
        roles: ['admin'],
    },
    {
        title: 'MENU.USERS',
        path: '/dashboard/content/users',
        icon: 'icofont icofont-people',
        routeName: 'users',
        roles: ['admin'],
        name: 'users',
        type: 'link',
        active: true,
    },
];
