import { ContentModel, DataBusModel } from '@app/models/databus.model';
import { environment } from 'environments/environment';
import { createReducer, on } from '@ngrx/store';
import { dataBusState } from '../state';
import {
    setSelectedInventoryTemplate,
    getFlightInventoriesSuccess,
    getFlightInventoriesFailed,
    getTemplatesSuccess,
    getTemplatesFailed,
    setCategoryBgImage,
    getAllUsersSuccess,
    getContentSuccess,
    getAllUsersFailed,
    getRequestSuccess,
    getFlightsSuccess,
    getFlightsFailed,
    getContentFailed,
    setFilterValue,
    updateNodeData,
    selectRowData,
    setRowData,
    setAgents,
    setCarts,
} from '../actions/dataBus.actions';
import * as _ from 'lodash';

const initialState = new DataBusModel() as dataBusState;
// TODO getContentFailed to use data model:HG

const dataBusReducer = createReducer(
    initialState,
    on(getContentSuccess, (state, action) => {
        const _d = { ...state };
        _d.content.background_categories = action.background_categories;
        _d.content.destinations = action.destinations;
        _d.content.kioskNames = action.kioskNames;
        _d.content.categories = action.categories;
        _d.content.concourses = action.concourses;
        _d.content.transport = action.transport;
        _d.content.icons = action.icons;
        _d.content.pois = action.pois;
        _d.content.hotels = action.hotels;
        _d.content.laxpois = action.pois;
        _d.content.attractions = action.attractions;
        _d.content.videos = action.videos;
        _d.content.category = action.category;
        _d.content.pages = action.pages;
        _d.content.category_dropdown = action.category_dropdown;
        _d.content.emergencybroadcast = action.emergencybroadcast;
        _d.content.survey = action.survey;
        _d.content.survey_config = action.survey_config;
        _d.content.kioskname = action.kioskname;
        _d.content.distributionLists = action.distributionLists;
        _d.content.specialUplift = action.specialUplift;
        _d.content.mapElementIDs = action.mapElementIDs;
        _d.content.tags = action.tags;
        _d.content.poiCategories = action.poiCategories;
        _d.content.airlines = action.airlines;
        _d.content.parentPages = action.parentPages;
        _d.content.cities = action.cities;
        _d.content.config = action.config;
        _d.content.home_banners = action.home_banners;
        _d.content.emergency = action.emergency;
        _d.content.poiIDName = action.poiIDName;
        _d.content.banners = action.banners;
        _d.content.news = action.news;
        _d.content.offers = action.offers;
        _d.content.airports = action.airports;
        _d.lastModified = action.lastModified;
        _d.content.equipment = action.equipment;
        _d.content.fares = action.fares;
        _d.content.terms = action.terms;

        return _d;
    }),
    on(getContentFailed, (state, action) => {
        const _d = { ...state };
        if (!action.isLastModified) {
            _d.content = new ContentModel();
        }

        return _d;
    }),
    on(setRowData, (state, action) => {
        const _d = { ...state };

        let apiRequestData = {};

        if (action.requestType === 'DELETE') {
            apiRequestData = {
                action: 'DELETE',
                key: action.key,
                index: action.index,
                value: action.value,
                arrayNode: action.node,
            };
        }

        _d.apiRequestData = apiRequestData;
        _d.content[action.node] = action.rowData;

        return _d;
    }),
    on(selectRowData, (state, action) => {
        const _d = { ...state };
        _d.selectedRow.node = action.node;
        _d.selectedRow.rowData = action.rowData;
        _d.selectedRow.nodeType = action.nodeType;
        return _d;
    }),
    on(updateNodeData, (state, action) => {
        const _d = { ...state };

        if (
            _d.selectedRow.node.toLowerCase() === 'emergencybroadcast' ||
            _d.selectedRow.node.toLowerCase() === 'terms' ||
            (_d.selectedRow.node.toLowerCase() === 'config' &&
                environment.appFlavor === 'mia') ||
            (_d.selectedRow.node.toLowerCase() === 'emergency' &&
                environment.appFlavor === 'elpaso')
        ) {
            _d.content[_d.selectedRow.node] = action.nodeData;
        } else {
            if (
                _d.selectedRow.node.toLowerCase() === 'survey' &&
                _d.selectedRow.nodeType.toLowerCase() === 'survey_config'
            ) {
                _d.content[_d.selectedRow.nodeType] = action.nodeData;
            } else if (
                _d.selectedRow.node.toLowerCase() === 'distributionlists'
            ) {
                _d.content.distributionLists = action.nodeData;
            } else if (_d.selectedRow.node.toLowerCase() === 'airlines') {
                if (_d.content.airlines[_d.selectedRow.rowData['code']]) {
                    const updatedNodeData = {
                        [Object.entries(action.nodeData).values().next()
                            .value[0]]: Object.entries(action.nodeData)
                            .values()
                            .next().value[1],
                    };

                    delete _d.content.airlines[_d.selectedRow.rowData['code']];

                    _d.content[_d.selectedRow.node] = {
                        ..._d.content[_d.selectedRow.node],
                        ...updatedNodeData,
                    };
                } else {
                    _d.content[_d.selectedRow.node] = {
                        ..._d.content[_d.selectedRow.node],
                        ...action.nodeData,
                    };
                }
            } else {
                let node = _d.selectedRow.node;

                if (_d.selectedRow.node === 'kiosknames') {
                    node = 'kioskNames';
                } else if (_d.selectedRow.node === 'poicategories') {
                    node = 'poiCategories';
                }

                const index = _d.content[node].findIndex((item) =>
                    _.isEqual(item, _d.selectedRow.rowData)
                );

                let apiRequestData = {};
                switch (action.requestType) {
                    case 'UPDATE':
                        apiRequestData = {
                            action: 'UPDATE',
                            key: action.key,
                            index,
                            data: action.nodeData,
                            value: action.value,
                            arrayNode: node,
                        };
                        break;

                    case 'ADD':
                        apiRequestData = {
                            action: 'UPDATE',
                            data: action.nodeData,
                            value: action.nodeData?.[action.key],
                            arrayNode: node,
                        };
                        break;

                    default:
                        break;
                }
                _d.apiRequestData = apiRequestData;

                if (index !== -1) {
                    _d.content[node][index] = action.nodeData;
                } else {
                    _d.content[node].push(action.nodeData);
                }
            }
        }
        return _d;
    }),
    on(setCategoryBgImage, (state, action) => {
        const _d = { ...state };
        _d.content.background_categories[action.category] = action.imageUrl;

        return _d;
    }),
    on(setFilterValue, (state, action) => {
        const _d = { ...state };
        _d.filteredValue.node = action.node;
        _d.filteredValue.filterValue = action.filterValue;
        _d.filteredValue.rowIndex = action.rowIndex;
        _d.filteredValue.pageNumber = action.pageNumber;
        _d.filteredValue.sorting = action.sorting;
        return _d;
    }),
    on(getAllUsersSuccess, (state, action) => {
        const _d = { ...state };
        _d.content.users = action.users;
        return _d;
    }),
    on(getAllUsersFailed, (state) => {
        const _d = { ...state };
        _d.content.users = [];
        return _d;
    }),
    on(getRequestSuccess, (state, action) => {
        const _d = { ...state };
        _d.requestForm[action.node] = action.apps;
        return _d;
    }),
    on(getFlightsSuccess, (state, action) => {
        const _d = { ...state };
        _d.content.flights = action.flights;
        _d.content.airports = action.airports;

        return _d;
    }),
    on(getFlightsFailed, (state) => {
        const _d = { ...state };
        _d.content.flights = [];
        return _d;
    }),
    on(setAgents, (state, action) => {
        const _d = { ...state };
        _d.agents = action.agents;
        return _d;
    }),
    on(setCarts, (state, action) => {
        const _d = { ...state };
        _d.carts = action.carts;
        return _d;
    }),
    on(getTemplatesSuccess, (state, action) => {
        const _d = { ...state };
        _d.content.templates = action.templates;
        return _d;
    }),
    on(getTemplatesFailed, (state) => {
        const _d = { ...state };
        _d.content.templates = [];
        return _d;
    }),
    on(getFlightInventoriesSuccess, (state, action) => {
        const _d = { ...state };
        _d.content.inventories = action.inventories;
        _d.inventoriesLastHours = action.inventoriesLastHours;
        _d.inventoriesLastDays = action.inventoriesLastDays;
        return _d;
    }),
    on(getFlightInventoriesFailed, (state) => {
        const _d = { ...state };
        _d.content.inventories = [];
        _d.inventoriesLastHours = '';
        _d.inventoriesLastDays = '';
        return _d;
    }),
    on(setSelectedInventoryTemplate, (state, action) => {
        const _d = { ...state };
        _d.selectedInventoryTemplate = action.selectedInventoryTemplate;
        return _d;
    })
);

export default function reducer(state: any, action: any): any {
    return dataBusReducer(state, action);
}
