export class DoorCoordinateSectionModel {
    key: string;
    lat: string;
    lng: string;
    name: {
        en: string;
        es?: string;
    };

    constructor(props: DoorCoordinateSectionModel) {
        return {
            key: props.key || '',
            lat: props.lat || '',
            lng: props.lng || '',
            name: {
                en: props.name?.en || '',
                es: props.name?.es ? props.name.es : props.name?.en || '',
            },
        };
    }
}

export class ConfigFormModel {
    contentTypes?: {
        labelLocalization?: {
            counter?: string;
            arrivals?: string;
            departures?: string;
            internationalArrivals?: string;
            baggage?: string;
            link?: string;
            phone?: string;
            pickup?: string;
            dropoff?: string;
        };
        doorCoordinates?: {
            arrivalDoors?: Array<DoorCoordinateSectionModel>;
            departureDoors?: Array<DoorCoordinateSectionModel>;
            parkingDoors?: Array<DoorCoordinateSectionModel>;
        };
    };

    constructor(props: ConfigFormInputsModel) {
        return {
            contentTypes: {
                labelLocalization: {
                    counter: props.counter || '',
                    arrivals: props.arrivals || '',
                    departures: props.departures || '',
                    internationalArrivals: props.internationalArrivals || '',
                    baggage: props.baggage || '',
                    link: props.link || '',
                    phone: props.phone || '',
                    pickup: props.pickup || '',
                    dropoff: props.dropoff || '',
                },
                doorCoordinates: {
                    arrivalDoors: props.arrivalDoors || [],
                    departureDoors: props.departureDoors || [],
                    parkingDoors: props.parkingDoors || [],
                },
            },
        };
    }
}
export class ConfigFormInputsModel {
    counter?: string;
    arrivals?: string;
    departures?: string;
    internationalArrivals?: string;
    baggage?: string;
    link?: string;
    phone?: string;
    pickup?: string;
    dropoff?: string;
    arrivalDoors?: Array<DoorCoordinateSectionModel>;
    departureDoors?: Array<DoorCoordinateSectionModel>;
    parkingDoors?: Array<DoorCoordinateSectionModel>;
}
