import { environment } from 'environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { Injectable } from '@angular/core';

declare let gtag;

@Injectable({
    providedIn: 'root',
})
export class GoogleAnalyticsService {
    menuItems;
    constructor(private router: Router) {}

    createTracker(): void {
        if (!environment.isGoogleAnalyticEnabled) {
            return;
        }
        this.router.events.subscribe((element) => {
            if (element instanceof NavigationEnd) {
                gtag('config', environment.googleAnalyticsTrackId, {
                    page_title: environment.pageName,
                    page_path: element.urlAfterRedirects,
                });
            }
        });
    }

    onAgentStatusChange(status: string, email: string): void {
        if (!environment.isGoogleAnalyticEnabled) {
            return;
        }

        let action;
        if (status === 'failed') {
            action = 'cms-login-failed';
        } else if (status === 'success') {
            action = 'cms-login-successful';
        } else if (status === 'logout') {
            action = 'cms-logout';
        }

        gtag('event', action, {
            event_category: 'CMS',
            event_label: email,
            value: 1,
        });
    }

    onMenuOpen(nodeName: any): void {
        if (!environment.isGoogleAnalyticEnabled) {
            return;
        }

        gtag('event', 'open', {
            event_category: 'CMS',
            event_label: this.menuItems.find((node) => node.name === nodeName)
                .title,
            value: 1,
        });
    }

    onResetPassword(nodeName: any): void {
        if (!environment.isGoogleAnalyticEnabled) {
            return;
        }

        gtag('event', 'reset-password', {
            event_category: 'CMS',
            event_label: this.menuItems.find((node) => node.name === nodeName)
                .title,
            value: 1,
        });
    }

    onTableSort(nodeName: any): void {
        if (!environment.isGoogleAnalyticEnabled) {
            return;
        }

        gtag('event', 'sort', {
            event_category: 'CMS',
            event_label: this.menuItems.find((node) => node.name === nodeName)
                .title,
            value: 1,
        });
    }

    onSaveContent(nodeName: any): void {
        if (!environment.isGoogleAnalyticEnabled) {
            return;
        }

        gtag('event', 'save', {
            event_category: 'CMS',
            event_label: this.menuItems.find((node) => node.name === nodeName)
                .title,
            value: 1,
        });
    }

    onDeletePress(nodeName: any): void {
        if (!environment.isGoogleAnalyticEnabled) {
            return;
        }

        gtag('event', 'delete', {
            event_category: 'CMS',
            event_label: this.menuItems.find((node) => node.name === nodeName)
                .title,
            value: 1,
        });
    }

    onAddNewButtonPress(nodeName: any): void {
        if (!environment.isGoogleAnalyticEnabled) {
            return;
        }

        gtag('event', 'add', {
            event_category: 'CMS',
            event_label: this.menuItems.find((node) => node.name === nodeName)
                .title,
            value: 1,
        });
    }

    onPlaceLookUp(title: any): void {
        if (!environment.isGoogleAnalyticEnabled) {
            return;
        }

        gtag('event', 'places-lookup', {
            event_category: 'CMS',
            event_label: title,
            value: 1,
        });
    }

    onImageUpload(nodeName: any): void {
        if (!environment.isGoogleAnalyticEnabled) {
            return;
        }

        gtag('event', 'image-upload', {
            event_category: 'CMS',
            event_label: this.menuItems.find((node) => node.name === nodeName)
                .title,
            value: 1,
        });
    }

    onDeleteImage(nodeName: any): void {
        if (!environment.isGoogleAnalyticEnabled) {
            return;
        }

        gtag('event', 'image-delete', {
            event_category: 'CMS',
            event_label: this.menuItems.find((node) => node.name === nodeName)
                .title,
            value: 1,
        });
    }

    onSaveButtonPress(nodeName: any): void {
        if (!environment.isGoogleAnalyticEnabled) {
            return;
        }

        gtag('event', 'save', {
            event_category: 'CMS',
            event_label: this.menuItems.find((node) => node.name === nodeName)
                .title,
            value: 1,
        });
    }

    onVideoLookUp(nodeName: any): void {
        if (!environment.isGoogleAnalyticEnabled) {
            return;
        }

        gtag('event', 'video-lookup', {
            event_category: 'CMS',
            event_label: this.menuItems.find((node) => node.name === nodeName)
                .title,
            value: 1,
        });
    }
}
