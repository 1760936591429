import { getContent, getContentByNode, getTemplates } from '@app/store/actions';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { selectDataBus, selectUser } from '../store/selectors';
import { environment } from 'environments/environment';
import { DateTimeService } from './date-time.service';
import { EventHubService } from './event-hub.service';
import { videoUrl } from '@app/models/videoUrl.mode';
import { MappingService } from './mapping.service';
import { Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    DeleteUserModel,
    UpdateUserModel,
    UserModel,
} from '@app/models/user.model';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    isGetContentSuccess = false;
    lastModified: string;
    saveSubscription;
    userToken: any;
    userRole: any;

    constructor(
        private dateTimeService: DateTimeService,
        private mapService: MappingService,
        private eventHub: EventHubService,
        private authService: AuthService,
        private store: Store<any>,
        private http: HttpClient
    ) {
        const userState$ = this.store.select(selectUser);

        userState$.subscribe((userItem) => {
            this.userToken =
                userItem && userItem.userToken ? userItem.userToken : '';
            this.userRole =
                userItem && userItem.userRole ? userItem.userRole : '';
        });

        const dataBusState$ = this.store.select(selectDataBus);

        dataBusState$.subscribe((dataBusItem) => {
            this.lastModified =
                dataBusItem && dataBusItem.lastModified
                    ? dataBusItem.lastModified
                    : null;
        });
    }

    getApiCall(apiNode: string, useLastModified = false): Observable<any> {
        let headers: any = {
            'Content-Type': 'application/json',
            'x-api-key': `${environment.apiConfig.apiKey}`,
            Authorization: `Bearer ${this.userToken}`,
        };

        if (useLastModified) {
            headers = {
                ...headers,
                'if-modified-since': this.lastModified || '',
            };
        }

        return this.http
            .get<any>(`${environment.apiConfig.projectEndpoint}/${apiNode}`, {
                headers,
                observe: 'response',
                responseType: 'json',
            })
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    const str = error.status.toString();
                    if (str.substring(0, 2) === '40' || str === '0') {
                        this.authService.signOut();
                    }
                    return throwError(error);
                })
            );
    }

    postApiCall(
        apiNode: string,
        apiBody: any,
        isDisabled?: boolean
    ): Observable<any> {
        let body = apiBody;

        if (
            apiNode === 'helpers/getVideoInfo' ||
            apiNode === 'getVideoInfo' ||
            apiNode === 'videos/getVideoInfo'
        ) {
            body = { videoURL: apiBody } as videoUrl;
        } else if (apiNode === 'contents/saveContents') {
            body = {
                userToken: this.userToken,
                data: apiBody,
            };
        } else if (apiNode === 'contents/updateArrayContents') {
            body = {
                userToken: this.userToken,
                ...apiBody,
            };
        } else if (apiNode === 'users/addUser') {
            body = { user: new UserModel(apiBody) };
        } else if (apiNode === 'users/updateUser') {
            if (isDisabled !== undefined) {
                apiBody.disabled = isDisabled;
            }

            body = {
                user: new UpdateUserModel(apiBody),
            };
        } else if (apiNode === 'users/deleteUser') {
            body = new DeleteUserModel({
                userId: apiBody.uid,
            });
        } else if (apiNode === 'flightList') {
            body = apiBody;
        } else if (apiNode === 'getRequests') {
            body = {
                status: apiBody,
                fromDate: this.dateTimeService.getAddedDays(-90),
            };
        } else if (apiNode === 'getMatchingTemplate') {
            body = {
                arrivalAirportCode: apiBody.arrivalAirportCode,
                departureTime: apiBody.departureTime,
                equipment: apiBody.equipment,
            };
        }

        return this.http
            .post<any>(
                `${environment.apiConfig.projectEndpoint}/${apiNode}`,
                JSON.stringify(body),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': `${environment.apiConfig.apiKey}`,
                        Authorization: `Bearer ${this.userToken}`,
                        'user-role': `${this.userRole}`,
                    },
                }
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    if (apiNode === 'getMatchingTemplates') {
                        return throwError(error);
                    } else if (apiNode === 'GetFlightInventories') {
                        return throwError(error);
                    } else {
                        const str = error.status.toString();
                        if (str.substring(0, 2) === '40') {
                            this.authService.signOut();
                        }
                        return throwError(error);
                    }
                })
            );
    }

    putApiCall(
        apiNode: string,
        apiBody: any,
        isDisabled?: boolean
    ): Observable<any> {
        const body = apiBody;

        return this.http
            .put<any>(
                `${environment.apiConfig.projectEndpoint}/${apiNode}`,
                JSON.stringify(body),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': `${environment.apiConfig.apiKey}`,
                        Authorization: `Bearer ${this.userToken}`,
                        'user-role': `${this.userRole}`,
                    },
                }
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    const str = error.status.toString();
                    if (str.substring(0, 2) === '40') {
                        this.authService.signOut();
                    }
                    return throwError(error);
                })
            );
    }

    postApiCallPromise(
        nodeApi: string,
        content: any,
        node?: string,
        additionalData?: any,
        triggerGetContentByNode = false
    ): any {
        let body = JSON.parse(JSON.stringify(content));
        let bodyAdditionalData = null;

        if (this.saveSubscription) {
            this.saveSubscription.unsubscribe();
        }

        if (
            nodeApi === 'contents/saveContents' ||
            nodeApi === 'updateTemplate' ||
            nodeApi === 'contents/updateArrayContents'
        ) {
            this.isGetContentSuccess = false;

            if (environment.appFlavor === 'lax') {
                body = this.mapService.mapLaxDataOnSaving(content, node);
            } else if (environment.appFlavor === 'lga') {
                body = this.mapService.mapLGASavingData(content, node);
            } else if (environment.appFlavor === 'neom') {
                body = this.mapService.mapNeomSavingData(content, node);
            } else if (environment.appFlavor === 'mea') {
                body = this.mapService.mapMeaSavingData(content, node);
            } else if (environment.appFlavor === 'mia') {
                body = this.mapService.mapMiaDataOnSaving(
                    content,
                    node,
                    additionalData,
                    nodeApi
                );

                if (node === 'pois') {
                    bodyAdditionalData = {
                        poiIDName: additionalData.poiIDName,
                    };
                }
            } else if (environment.appFlavor === 'elpaso') {
                body = this.mapService.mapELPasoDataOnSaving(content, node);
            } else if (
                environment.appFlavor === 'bwa' ||
                environment.appFlavor === 'mvp' ||
                environment.appFlavor === 'afw'
            ) {
                body = this.mapService.mapAirlineAppDataOnSaving(content, node);
            }
        }

        if (nodeApi === 'deleteTemplate') {
            return new Promise((resolve, reject) => {
                this.saveSubscription = this.putApiCall(
                    nodeApi,
                    body
                ).subscribe(
                    (response) => {
                        if (node === 'templates') {
                            this.store.dispatch(getTemplates());
                            resolve(this.isGetContentSuccess);
                        } else {
                            resolve(response);
                        }
                    },
                    (error) => {
                        const str = error.status.toString();
                        if (str.substring(0, 2) === '40') {
                            this.authService.signOut();
                        }
                        reject(error);
                    }
                );
            });
        } else {
            return new Promise((resolve, reject) => {
                this.saveSubscription = this.postApiCall(
                    nodeApi,
                    body
                ).subscribe(
                    (response) => {
                        if (
                            nodeApi === 'contents/saveContents' ||
                            nodeApi === 'contents/updateArrayContents'
                        ) {
                            if (response.status) {
                                if (
                                    node === 'pois' &&
                                    environment.appFlavor === 'mia'
                                ) {
                                    this.postApiCall(
                                        'contents/saveContents',
                                        bodyAdditionalData
                                    ).subscribe();
                                }

                                this.store.dispatch(
                                    triggerGetContentByNode
                                        ? getContentByNode()
                                        : getContent()
                                );

                                resolve(true);
                            }
                        } else if (nodeApi === 'updateTemplate') {
                            if (response.status) {
                                this.store.dispatch(getTemplates());
                                resolve(response);
                            }
                        } else {
                            resolve(response);
                        }
                        if (
                            environment.appFlavor === 'mia' ||
                            environment.appFlavor === 'bwa' ||
                            environment.appFlavor === 'mvp' ||
                            environment.appFlavor === 'afw'
                        ) {
                            this.eventHub.triggerSavingResult.emit(true);
                        }
                    },
                    (error) => {
                        if (nodeApi === 'getMatchingTemplates') {
                            reject(error);
                        } else {
                            const str = error.status.toString();
                            if (str.substring(0, 2) === '40') {
                                this.authService.signOut();
                            }
                            reject(error);
                        }
                    }
                );
            });
        }
    }
}
