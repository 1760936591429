import { createAction, props } from '@ngrx/store';
import { types } from './types';

export const setUserToken = createAction(
    types.SET_USER_TOKEN,
    props<{
        userToken: string;
        email: string;
        userLoginTime: string;
        userRole: string;
        lastVisitedPage?: string;
    }>()
);

export const setUserToken_And_LoginTime = createAction(
    types.SET_USER_TOKEN_AND_LOGIN_TIME,
    props<{
        userToken: string;
        userLoginTime: string;
    }>()
);

export const resetUserToken = createAction(types.RESET_USER_TOKEN);

export const setUserLastVisitedPage = createAction(
    types.SET_USER_LAST_VISITED_PAGE,
    props<{
        lastVisitedPage: string;
    }>()
);
