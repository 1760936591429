import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import * as timezone from 'dayjs/plugin/timezone';
import { Injectable } from '@angular/core';
import * as utc from 'dayjs/plugin/utc';
import * as dayjs from 'dayjs';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(localizedFormat);

@Injectable({
    providedIn: 'root',
})
export class DateTimeService {
    constructor() {}

    formatDate(date: string | number = '', dateFormat: string = ''): string {
        return dayjs(date).format(dateFormat);
    }

    getAddedDays(daysToAdd: number): any {
        return dayjs().add(daysToAdd, 'd').utc();
    }

    getUnix(date: string): number {
        return dayjs(date).unix();
    }
}
