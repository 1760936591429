export const environment = {
    pageName: 'El Paso CMS',
    appFlavor: 'elpaso',
    production: false,
    appEnv: 'qa',
    googleMapsKey: 'AIzaSyCW2HGzKr_ppn9Q-KBz2tPJijGH0IYSjbw',
    apiConfig: {
        projectEndpoint: 'https://elp-api-qa.itxi.aero',
        apiKey: 'be8ef554-dd22-45ee-8cef-7664174e66e0',
    },
    firebase: {
        apiKey: 'AIzaSyCW2HGzKr_ppn9Q-KBz2tPJijGH0IYSjbw',
        authDomain: 'elpaso-paxassist-qa.firebaseapp.com',
        databaseURL: 'https://elpaso-paxassist-qa-default-rtdb.firebaseio.com',
        projectId: 'elpaso-paxassist-qa',
        storageBucket: 'elpaso-paxassist-qa.appspot.com',
        messagingSenderId: '461451123908',
        appId: '1:461451123908:web:eea53a102bf4ffcc1bfd91',
        measurementId: 'G-5XSZDGE7FS',
    },
    googleAnalyticsTrackId: '',
    rolesWithLoginAccess: [
        {
            role: 'admin',
            route: 'dashboard/content/users',
            node: 'users',
        },
        {
            role: 'power_user',
            route: 'dashboard/content/attractions',
            node: 'attractions',
        },
        {
            role: 'viewer',
            route: 'dashboard/content/attractions',
            node: 'attractions',
        },
    ],
    sessionTokenCheckInterval: 300000,
    getAllFlightsListInterval: 300000,
    sessionTokenExtension: true,
    defaultRoute: 'dashboard/content/users',
    enableRandomBackground: false,
    isGoogleAnalyticEnabled: false,
    airportCoordinates: {
        latitude: 31.80531216145772,
        longitude: -106.3824336070223,
        ISO3166_1_Alpha_2: 'US',
    },
    videoCategories: [],
    userTableLanguages: [{ key: 'en', label: 'LANGUAGES.ENGLISH_ONLY' }],
    userTablesRole: [
        { key: 'admin', label: 'ROLES.ADMIN' },
        { key: 'power_user', label: 'ROLES.POWER_USER' },
        {
            key: 'viewer',
            label: 'ROLES.VIEWER',
        },
    ],
    formLanguages: [
        { key: 'en', title: 'LANGUAGES.ENGLISH', label: 'FORMS.EN_LANG' },
        { key: 'es', title: 'LANGUAGES.ESPANOL', label: 'FORMS.ES_LANG' },
    ],
    appCategories: [],
    payment_method: [],
    payment_status: [],
    orientationArray: [],
    containerType: [],
    contentType: [],
    contentItems: [],
    equipmentType: [],
    departureTime: [],
    sectors: [],
    cartItems: [],
};
